import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="footer wow fadeIn" data-wow-delay="0.3s">
                <div className="container">
                    <div className="row footeritems">
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-contact">
                                <h2>Office Contact</h2>
                                <p><i className="fa fa-map-marker-alt"></i>Shree Dadu-Dayal Construction And Builders, Zone 9, Ring Road Dantli, Jaipur</p>
                                <a href="tel:9001761512"> <i className="fa fa-phone-alt"></i>+91 9001761512</a>
                                <p><i className="fa fa-envelope"></i><a href="mailto:manishsharma95sharma@gmail.com" className="email-button">manishsharma95sharma@gmail.com</a></p>
                                <div className="footer-social">
                                    <a href="https://www.facebook.com/profile.php?id=100003964747078&mibextid=ibOpuV" target="_self"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/bagda9786?igsh=MWViMXZxcjZxd3VuNg==" target="_self"><i className="fab fa-instagram" style={{ marginLeft: "3px" }}></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-link">
                                <h2>Services Areas</h2>
                                <Link >Building Construction</Link>
                                <Link >House Renovation</Link>
                                <Link >Architecture Design</Link>
                                <Link >Interior Design</Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-link">
                                <h2>Useful Pages</h2>
                                <Link to="/about">About Us</Link>
                                <Link to="/contact">Contact Us</Link>
                                <Link to="/project">Projects</Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="newsletter">
                                <h2>Chat With Us</h2>
                                <div className="footer-social">
                                    <a href="https://api.whatsapp.com/send?phone=919001761512&text=Send20%a20%quote" target="_self"><i className="fab fa-whatsapp " aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container footer-menu">
                    <div className="f-menu">
                        <p className="p1">Thanks For Visit </p>
                        <p>Shree Dadu-Dayal Construction And Builders  </p>
                    </div>
                </div>
                <div className="container copyright">
                    <div className="row">
                        <div className="col-md-6">
                            <p>&copy; <a href="">Shree Dadu-Dayal Construction And Builders </a>, All Right Reserved.</p>
                        </div>
                        <div className="col-md-6">
                            <p>Developed By <a href="https://www.acorebit.com" title="www.acorebit.com">ACoreBit it solutions, Jaipur</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;