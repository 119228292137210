import React, { useEffect } from "react";
import About from "./about";
import fontawesome from '@fortawesome/fontawesome'
import { faCheckSquare, faCoffee } from '@fortawesome/fontawesome-free-solid'
import Service from "./service";
import Project from "./project";
import { Link } from "react-router-dom";


const styles = {
    height: "70%",
    width: "80%"
};

fontawesome.library.add(faCheckSquare, faCoffee);

const Home = () => {
    useEffect(() => {
        // Start the carousel when the component mounts
        const carousel = document.querySelector('#carousel');
        const carouselInstance = new window.bootstrap.Carousel(carousel, {
            interval: 2000 
        });
        carouselInstance.cycle(); 

        window.scrollTo(0, 0);


    }, []);


    return (
        <>
            <div className="wrapper">
                <div id="carousel" className="carousel slide" data-ride="carousel" data-interval="2000">
                    <ol className="carousel-indicators">
                        <li data-target="#carousel" data-slide-to="0" className="active"></li>
                        <li data-target="#carousel" data-slide-to="1"></li>
                        <li data-target="#carousel" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="img/home3.jpg" alt="Carousel-Image" />
                            <div className="carousel-caption">
                                <p className="animated fadeInRight">We Are Professional</p>
                                <h1 className="animated fadeInLeft">For Your Dream Project</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/home2.jpg" alt="Carousel-Image" />
                            <div className="carousel-caption">
                                <p className="animated fadeInRight">Professional Builder</p>
                                <h1 className="animated fadeInLeft">We Build Your Home</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-3.jpg" alt="Carousel-Image" />
                            <div className="carousel-caption">
                                <p className="animated fadeInRight">We Are Trusted</p>
                                <h1 className="animated fadeInLeft">For Your Dream Home</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                {/* <!-- Carousel End --> */}

                {/* <!-- Feature Start--> */}
                <div className="feature wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12">
                                <div className="feature-item">
                                    <div className="feature-icon1">
                                        <div >
                                            <img style={styles} src="/img/worker-logo.png" alt="Worker Logo" />
                                        </div>
                                    </div>
                                    <div className="feature-text">
                                        <h3>Expert Worker</h3>
                                        <p>
                                            Experts for Outstanding Work
                                            Our skilled team ensures top-notch results, every time. Trust us to bring your project to life flawlessly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="feature-item">
                                    <div >
                                        <img style={styles} src="/img/Quality-logo.png" alt="Worker Logo" />
                                    </div>
                                    <div className="feature-text">
                                        <h3>Quality Work</h3>
                                        <p>  we prioritize quality from the very foundation. We meticulously select premium materials, guaranteeing durability, longevity, and an impeccable finish for your project.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="feature-item">
                                    <div >
                                        <img style={styles} src="/img/24-7.jpg" alt="Worker Logo" />
                                    </div>
                                    <div className="feature-text">
                                        <h3>24/7 Support</h3>
                                        <p>we understand that building dreams isn't confined to regular business hours. That's why we're proud to offer 24/7 support to our valued clients. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Service />
            <Project />
            <About />
        </>
    );
}

export default Home;