import React ,{ useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
const Header = () => {

    const [toggle, setToggle] = useState(false);

    const togglebar = () => {
        setToggle(!toggle);
    };

    const location = useLocation();
    const [activeItem, setActiveItem] = useState('');

    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location]);

    return (
        <div className="headermain">
            <div className="top-bar">
                <div className="subheader">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-6 m-header">
                            <div className="logo-div">
                                <Link to="/"><img className="logo_icon" src="img/Logo/logo-icon.png"></img></Link>
                                <img className="logo_name" src="img/Logo/logo-name.png"></img>
                            </div>
                            <div className=" col-md-6 topbar-social-icon">
                                <div className="footer-social">
                                    <a href="https://www.facebook.com/profile.php?id=100003964747078&mibextid=ibOpuV" target="_self"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/bagda9786?igsh=MWViMXZxcjZxd3VuNg==" target="_self"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 d-none d-lg-block">
                            <div className="row">
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-text">
                                            <h3>Open - Close</h3>
                                            <p>8:00 AM - 9:00 PM</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">

                                        <div className="top-bar-text">
                                            <h3>Call Us</h3>
                                            <a href="tel:9001761512">+91 9001761512</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-text">
                                            <h3>Email Us</h3>
                                            <a href="mailto:manishsharma95sharma@gmail.com" className="email-button">manishsharma95sharma@gmail.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nav-bar">
                <div className="subheader">
                    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                        <a href="" className="navbar-brand">MENU</a>
                        <button type="button" onClick={togglebar} className="navbar-toggler">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${toggle ? 'show' : ''}`} id="navbarCollapse">
                            <div className="navbar-nav mr-auto">
                                <Link to="/" className={activeItem === '/' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>Home</Link>
                                <Link to="/about" className={activeItem === '/about' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>About</Link>
                                <Link to="/service" className={activeItem === '/service' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>Service</Link>
                                <Link to="/project" className={activeItem === '/project' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>Projects</Link>
                                <Link to="/contact" className={activeItem === '/contact' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>Contact</Link>
                            </div>
                            <div className="ml-auto">
                                <div className="footer-social">
                                    <a href="https://www.facebook.com/profile.php?id=100003964747078&mibextid=ibOpuV" target="_self"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/bagda9786?igsh=MWViMXZxcjZxd3VuNg==" target="_self"><i className="fab fa-instagram"></i></a>
                                </div>
                                <Link className="btn" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div >
    );
}
export default Header;