import React, { useEffect } from "react";

const Project = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="team">
                <div className="container">
                    <h1>Projects</h1>
                    <div className="section-header text-center">
                        <hr></hr>
                        <h2>Completed Projects</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="img/home1.jpg" alt="Project-Image"  />
                                </div>
                                <div className="team-text">
                                    <p>26 sector,Pratap Nagar,Jaipur</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="img/home2.jpg" alt="Project-Image"  />
                                </div>
                                <div className="team-text">
                                    <p>Kanadwas ,Ring road, Jaipur</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="img/home3.jpg" alt="Project-Image"  />
                                </div>
                                <div className="team-text">
                                    <p>db 144, Jagatpura,Jaipur</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="img/home4.jpg" alt="Project-Image"  />
                                </div>
                                <div className="team-text">
                                    <p>Krishi nagar,Vatika,Jaipur</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="section-header text-center">
                        <hr></hr>
                        <h2>Ongoing Projects</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="img/Proj_img/loc1.jpg" alt="Project-Image"  />
                                </div>
                                <div className="team-text">
                                    <p>Kanadwas, Ring road, Jaipur</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="img/Proj_img/loc2.jpg" alt="Project-Image"  />
                                </div>
                                <div className="team-text">
                                    <p>Sitaram Vihar,Luniyawas,Jaipur</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="img/Proj_img/loc3.jpg" alt="Project-Image" />
                                </div>
                                <div className="team-text">
                                    <p>Nilay kunj,Siroli,Jaipur</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="img/Proj_img/loc4.jpg" alt="Project-Image" />
                                </div>
                                <div className="team-text">
                                    <p>26 sector,Pratap Nagar,Jaipur</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Project;