import React, { useEffect } from "react";

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="about-img">
                                <img style={{ width: "100%" }} src="img/home2.jpg"/>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 welcometxt ">
                            <h2 className="mt-5" style={{ color: "brown" }}>Welcome to Shree Dadu-Dayal Construction And Builders - Building Dreams into Reality</h2>
                            <div className="section-header text-left">
                                <h3>10+ Years Experience</h3>
                            </div>
                            <div className="about-text">
                                <div className="about wow fadeInUp" data-wow-delay="0.1s">
                                    <p>
                                        we understand that every construction project is more than just bricks and mortar - it's about turning your vision into a tangible reality. With years of experience and a passion for quality craftsmanship, we are committed to delivering exceptional construction solutions tailored to your needs.
                                    </p>
                                    <p>
                                        Whether you're looking to build a new home, renovate an existing property, or undertake a commercial construction project, We have the expertise and resources to bring your ideas to life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="fact">
                    <div className="container-fluid">
                        <div className="row counters">
                            <div className="col-md-6 fact-left wow slideInLeft">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="fact-text">
                                            <h2 data-toggle="counter-up">108</h2>
                                            <p>Expert Workers</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="fact-text">
                                            <h2 data-toggle="counter-up">25</h2>
                                            <p>Happy Clients</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 fact-right wow slideInRight">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="fact-text">
                                            <h2 data-toggle="counter-up">25</h2>
                                            <p>Completed Projects</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="fact-text">
                                            <h2 data-toggle="counter-up">13</h2>
                                            <p>Running Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;